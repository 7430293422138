<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          <h2>Пропозиції по тендеру</h2>
          <p class="mb-0">ID тендеру: <span class="font-weight-bold">{{ order.id }}</span></p>
          <p v-if="order !== null"><span class="font-weight-bold">{{ order.branch_a }}</span>
            {{ order.branch_a_address }}</p>
        </CCardHeader>
        <CCardBody>
          <CDataTable
              hover
              striped
              :items="allOffersList"
              :fields="AllDataOfferss"
              :pagination="$options.paginationProps"
              :sorter-value="{column:'proposal_result', asc:false}"
              :table-filter="{lazy:false, placeholder:'Знайти...', label:' '}"
              index-column
              :noItemsView="{ noResults: 'За результатами пошуку нічого не знайдено', noItems: 'no items available' }"
              sorter
          >
            <!--                        clickable-rows-->
            <template #over-table>
              <CRow class="text-right">
                <CCol class="m-1 mb-3">
                  <CButton v-if="showBtn()" color="warning" @click="createOffer">Подати ще одну пропозицію</CButton>
                </CCol>
              </CRow>
            </template>

            <template #download_speed="data">
              <td>
                {{ data.item.download_speed }}/{{ data.item.upload_speed }}
              </td>
            </template>

            <template #show_more="data">
              <td>
                <CButton
                    class="my-1 mr-0"
                    color="primary"
                    variant="outline"
                    @click="showDetails(data.item.id)"
                >Детальніше
                </CButton>
              </td>
            </template>

          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";
import {routerGoToName} from "@/utils/routerUtils";

export default {
  name: "ShowAllOffersDetails",
  data() {
    return {
      allOffersList: [{}],
      order: {},
      AllDataOfferss: [
        {key: 'id', label: 'ID - пропозиції'},
        {key: 'connection_cost', label: 'Вартість підключення'},
        {key: 'monthly_payment', label: 'Місячна абонплата'},
        {key: 'download_speed', label: 'Швидкість, Мбіт/с (Вх/Вих) (Європа)'},
        {key: 'show_more', label: 'Деталі'},
      ],
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next',
  },
  created() {
    this.getTenderByID();
    this.getAllOffersDetails();
  },
  methods: {
    showBtn() {
      if (this.order.status === 'OpenOrder') {
        return true;
      }
      return false;
    },

    getAllOffersDetails() {
      let self = this;
      axios.get(`/api/account/offers/${self.$route.params.id}/active`)
          .then(function (response) {
            self.$set(self, 'allOffersList', response.data)
          }).catch(function (error) {
        if (error.response.data.message === 'OrderOffersNotFound') {

          return self.$router.push(`/tenders/${self.$route.params.id}`);
        }
      });
    },

    getTenderByID() {
      let self = this;
      axios.get(`/api/account/orders/${this.$route.params.id}`)
          .then(function (response) {
            self.order = response.data;
          })
          .catch(function () {
          });
    },

    showDetails(offers_id) {
      routerGoToName('TendersViewOfferDetail', 'params', {offers_id: offers_id})
      // return this.$router.push(`/offer/${offers_id}`);
    },

    OffersLink(order_id) {
      return `/tenders/${order_id.toString()}/offer`
    },

    createOffer() {
      let tendersLink = this.OffersLink(this.$route.params.id);
      let router = this.$router.resolve({path: tendersLink});
      window.open(router.href, '_blank');
    },

  }
}
</script>
